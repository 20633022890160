import { createContext, type ReactNode, useContext, useEffect, useState } from 'react'
import { supabase } from '~/lib/supabase'
import type { Session } from '@supabase/supabase-js'

export const AuthContext = createContext<{ session: Session | null; isLoaded: boolean }>({
  session: null,
  isLoaded: false,
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [session, setSession] = useState<Session | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(false)
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      setIsLoaded(true)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_, session) => {
      setSession(session)
    })

    return () => subscription.unsubscribe()
  }, [])

  return <AuthContext.Provider value={{ session, isLoaded }}>{children}</AuthContext.Provider>
}
